.c-blockquote
{
  display: inline-block;
  margin: 0;

  &::before,
  &::after {
    color: $blue-600;
    content: '“';
    font-size: 3rem;
    line-height: 0;
    vertical-align: -0.4em;
  }

  &::after {
    vertical-align: -0.6em;
  }

  p:first-child {
    display: inline;
  }
}

.c-blockquote__caption {
  font-size: 1.5rem;
  font-weight: 700;
  margin: calc(3*8px) 0 0;
}
