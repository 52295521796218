@include media-breakpoint-up(md)
{
  .c-text-with-dash {
    display: inline-block;

    &::after {
      display: inline;
      content: '\00a0\2014';
      font-family: 'Font Awesome 5 Free';
      font-size: 70px;
      line-height: 0;
      margin-left: .5rem;
      color: $primary;
      vertical-align: sub;
    }

    span {
      display: inline;
    }

    &.c-text-with-dash--sm {
      &::after {
        vertical-align: inherit;
        font-size: 40px;
      }
    }
  }
}
