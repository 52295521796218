.c-button {
  display: inline-flex;
  padding: 0.875rem 1.5rem;
  line-height: 1;
  // font-size: 1.125rem;
  font-weight: 500;
  color: $white;
  text-decoration: none;
  background-color: $primary;
  border: 2px solid transparent;
  border-radius: 9999px;
  transition: background-color .5s, color .5s, border-color .5s;

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: darken($primary, 10%);
  }
}

.c-button--outline {
  background-color: transparent;
  border-color: $white;
  border-radius: $radius-md;
}

.btn-outline-black {
  color: $black !important;
  border: 1px solid $gray-500 !important;
  background: white !important;

  &:hover {
    background: $black !important;
    color: $white !important;
    border: 1px solid $black !important;
  }
}

.btn-outline-gray-500 {
  color: $gray-500 !important;
  border: 1px solid $gray-500 !important;
  background: white !important;

  &:hover {
    background: $black !important;
    color: $white !important;
    border: 1px solid $black !important;
  }
}

.btn-outline-white {
  color: $white !important;
  border: 2px solid $white !important;
  background: transparent !important;

  border-radius: $input-border-radius;
}

.btn:focus, .btn.focus {
  outline: auto;
  outline-color: black;
}

.btn-round {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: rem(16);
}

.text-primary .btn-primary {
  color: $white;
}