.c-page {}

.c-page__header {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.c-page__heading {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin: 1em 0 0;
  text-align: center;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
}

.c-page__header--image {
  background-color: $gray-900;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 85px 0 0;
  min-height: 300px;
  @include media-breakpoint-up(lg) {
    min-height: 400px;
    padding-top: 100px;
    padding-bottom: 80px;
  }
  &:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba($black, 0.2);
    z-index: 0;
  }
  & > div {
    position: relative;
  }

  .c-page__heading {
    color: $white;
    margin: 0;
  }
}

.c-page__content {
  position: relative;
  z-index: 10;
  padding: 2em 0 3em;
  @include media-breakpoint-up(lg) {
    padding: 4em 0 5em;
  }

  h2 {
      font-weight: 600;
      margin-bottom: 0.8em;
      margin-top: 1.6em;
      @include media-breakpoint-down(md) {
        font-size: 1.625rem;
      }
  }
  p {
    margin-bottom: 1.5em;
  }
}

.header-job {
  padding-top: 100px;

  @include media-breakpoint-up(md) {
    height: 350px;
    padding-top: 0;
  }
}