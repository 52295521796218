$li-angle-width: 1rem;

.content {
  ul, ol {
    li {
      margin-top: .25em;
      margin-bottom: .25em;
    }
  }
}

.list-arrow {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding-left: $li-angle-width;
    position: relative;
    margin-top: .667em;
    margin-bottom: .667em;
    &:before { 
      @include faw-icon($fa-var-chevron-right,10);
      width: $li-angle-width;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      line-height: 2.5em;
    }
    ul, ol {
      margin-bottom: 1em;
    }
  }
}

.list-alpha {
  list-style-type: lower-alpha;
}