.u-m-0 { margin: 0; }
.u-m-1 { margin: 0.25rem; }
.u-m-2 { margin: 0.5rem; }
.u-m-3 { margin: 0.75rem; }
.u-m-4 { margin: 1rem; }
.u-m-5 { margin: 1.25rem; }
.u-m-6 { margin: 1.5rem; }
.u-m-8 { margin: 2rem; }
.u-m-10 { margin: 2.5rem; }
.u-m-12 { margin: 3rem; }
.u-m-16 { margin: 4rem; }
.u-m-20 { margin: 5rem; }
.u-m-24 { margin: 6rem; }
.u-m-32 { margin: 8rem; }
.u-m-40 { margin: 10rem; }
.u-m-48 { margin: 12rem; }
.u-m-56 { margin: 14rem; }
.u-m-64 { margin: 16rem; }
.u-m-auto { margin: auto; }
.u-m-px { margin: 1px; }
.u--m-1 { margin: -0.25rem; }
.u--m-2 { margin: -0.5rem; }
.u--m-3 { margin: -0.75rem; }
.u--m-4 { margin: -1rem; }
.u--m-5 { margin: -1.25rem; }
.u--m-6 { margin: -1.5rem; }
.u--m-8 { margin: -2rem; }
.u--m-10 { margin: -2.5rem; }
.u--m-12 { margin: -3rem; }
.u--m-16 { margin: -4rem; }
.u--m-20 { margin: -5rem; }
.u--m-24 { margin: -6rem; }
.u--m-32 { margin: -8rem; }
.u--m-40 { margin: -10rem; }
.u--m-48 { margin: -12rem; }
.u--m-56 { margin: -14rem; }
.u--m-64 { margin: -16rem; }
.u--m-px { margin: -1px; }

.u-my-0 { margin-top: 0; margin-bottom: 0; }
.u-mx-0 { margin-left: 0; margin-right: 0; }
.u-my-1 { margin-top: 0.25rem; margin-bottom: 0.25rem; }
.u-mx-1 { margin-left: 0.25rem; margin-right: 0.25rem; }
.u-my-2 { margin-top: 0.5rem; margin-right: 0.5rem; }
.u-my-3 { margin-top: 0.75rem; margin-bottom: 0.75rem; }
.u-mx-3 { margin-left: 0.75rem; margin-right: 0.75rem; }
.u-my-4 { margin-top: 1rem; margin-bottom: 1rem; }
.u-mx-4 { margin-left: 1rem; margin-right: 1rem; }
.u-my-5 { margin-top: 1.25rem; margin-bottom: 1.25rem; }
.u-mx-5 { margin-left: 1.25rem; margin-right: 1.25rem; }
.u-my-6 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
.u-mx-6 { margin-left: 1.5rem; margin-right: 1.5rem; }
.u-my-8 { margin-top: 2rem; margin-bottom: 2rem; }
.u-mx-8 { margin-left: 2rem; margin-right: 2rem; }
.u-my-10 { margin-top: 2.5rem; margin-bottom: 2.5rem; }
.u-mx-10 { margin-left: 2.5rem; margin-right: 2.5rem; }
.u-my-12 { margin-top: 3rem; margin-bottom: 3rem; }
.u-mx-12 { margin-left: 3rem; margin-right: 3rem; }
.u-my-16 { margin-top: 4rem; margin-bottom: 4rem; }
.u-mx-16 { margin-left: 4rem; margin-right: 4rem; }
.u-my-20 { margin-top: 5rem; margin-bottom: 5rem; }
.u-mx-20 { margin-left: 5rem; margin-right: 5rem; }
.u-my-24 { margin-top: 6rem; margin-bottom: 6rem; }
.u-mx-24 { margin-left: 6rem; margin-right: 6rem; }
.u-my-32 { margin-top: 8rem; margin-bottom: 8rem; }
.u-mx-32 { margin-left: 8rem; margin-right: 8rem; }
.u-my-40 { margin-top: 10rem; margin-bottom: 10rem; }
.u-mx-40 { margin-left: 10rem; margin-right: 10rem; }
.u-my-48 { margin-top: 12rem; margin-bottom: 12rem; }
.u-mx-48 { margin-left: 12rem; margin-right: 12rem; }
.u-my-56 { margin-top: 14rem; margin-bottom: 14rem; }
.u-mx-56 { margin-left: 14rem; margin-right: 14rem; }
.u-my-64 { margin-top: 16rem; margin-bottom: 16rem; }
.u-mx-64 { margin-left: 16rem; margin-right: 16rem; }
.u-my-auto { margin-top: auto; margin-bottom: auto; }
.u-mx-auto { margin-left: auto; margin-right: auto; }
.u-my-px { margin-top: 1px; margin-bottom: 1px; }
.u-mx-px { margin-left: 1px; margin-right: 1px; }
.u--my-1 { margin-top: -0.25rem; margin-bottom: -0.25rem }
.u--mx-1 { margin-left: -0.25rem; margin-right: -0.25rem }
.u--my-2 { margin-top: -0.5rem; margin-bottom: -0.5rem; }
.u--mx-2 { margin-left: -0.5rem; margin-right: -0.5rem; }
.u--my-3 { margin-top: -0.75rem; margin-bottom: -0.75rem }
.u--mx-3 { margin-left: -0.75rem; margin-right: -0.75rem }
.u--my-4 { margin-top: -1rem; margin-bottom: -1rem; }
.u--mx-4 { margin-left: -1rem; margin-right: -1rem; }
.u--my-5 { margin-top: -1.25rem; margin-bottom: -1.25rem }
.u--mx-5 { margin-left: -1.25rem; margin-right: -1.25rem }
.u--my-6 { margin-top: -1.5rem; margin-bottom: -1.5rem; }
.u--mx-6 { margin-left: -1.5rem; margin-right: -1.5rem; }
.u--my-8 { margin-top: -2rem; margin-bottom: -2rem; }
.u--mx-8 { margin-left: -2rem; margin-right: -2rem; }
.u--my-10 { margin-top: -2.5rem; margin-bottom: -2.5rem; }
.u--mx-10 { margin-left: -2.5rem; margin-right: -2.5rem; }
.u--my-12 { margin-top: -3rem; margin-bottom: -3rem; }
.u--mx-12 { margin-left: -3rem; margin-right: -3rem; }
.u--my-16 { margin-top: -4rem; margin-bottom: -4rem; }
.u--mx-16 { margin-left: -4rem; margin-right: -4rem; }
.u--my-20 { margin-top: -5rem; margin-bottom: -5rem; }
.u--mx-20 { margin-left: -5rem; margin-right: -5rem; }
.u--my-24 { margin-top: -6rem; margin-bottom: -6rem; }
.u--mx-24 { margin-left: -6rem; margin-right: -6rem; }
.u--my-32 { margin-top: -8rem; margin-bottom: -8rem; }
.u--mx-32 { margin-left: -8rem; margin-right: -8rem; }
.u--my-40 { margin-top: -10rem; margin-bottom: -10rem; }
.u--mx-40 { margin-left: -10rem; margin-right: -10rem; }
.u--my-48 { margin-top: -12rem; margin-bottom: -12rem; }
.u--mx-48 { margin-left: -12rem; margin-right: -12rem; }
.u--my-56 { margin-top: -14rem; margin-bottom: -14rem; }
.u--mx-56 { margin-left: -14rem; margin-right: -14rem; }
.u--my-64 { margin-top: -16rem; margin-bottom: -16rem; }
.u--mx-64 { margin-left: -16rem; margin-right: -16rem; }
.u--my-px { margin-top: -1px; margin-bottom: -1px; }
.u--mx-px { margin-left: -1px; margin-right: -1px; }

.u-mt-0 { margin-top: 0; }
.u-mr-0 { margin-right: 0; }
.u-mb-0 { margin-bottom: 0; }
.u-ml-0 { margin-left: 0; }
.u-mt-1 { margin-top: 0.25rem; }
.u-mr-1 { margin-right: 0.25rem; }
.u-mb-1 { margin-bottom: 0.25rem; }
.u-ml-1 { margin-left: 0.25rem; }
.u-mt-2 { margin-top: 0.5rem; }
.u-mr-2 { margin-right: 0.5rem; }
.u-mb-2 { margin-bottom: 0.5rem; }
.u-ml-2 { margin-left: 0.5rem; }
.u-mt-3 { margin-top: 0.75rem; }
.u-mr-3 { margin-right: 0.75rem; }
.u-mb-3 { margin-bottom: 0.75rem; }
.u-ml-3 { margin-left: 0.75rem; }
.u-mt-4 { margin-top: 1rem; }
.u-mr-4 { margin-right: 1rem; }
.u-mb-4 { margin-bottom: 1rem; }
.u-ml-4 { margin-left: 1rem; }
.u-mt-5 { margin-top: 1.25rem; }
.u-mr-5 { margin-right: 1.25rem; }
.u-mb-5 { margin-bottom: 1.25rem; }
.u-ml-5 { margin-left: 1.25rem; }
.u-mt-6 { margin-top: 1.5rem; }
.u-mr-6 { margin-right: 1.5rem; }
.u-mb-6 { margin-bottom: 1.5rem; }
.u-ml-6 { margin-left: 1.5rem; }
.u-mt-8 { margin-top: 2rem; }
.u-mr-8 { margin-right: 2rem; }
.u-mb-8 { margin-bottom: 2rem; }
.u-ml-8 { margin-left: 2rem; }
.u-mt-10 { margin-top: 2.5rem; }
.u-mr-10 { margin-right: 2.5rem; }
.u-mb-10 { margin-bottom: 2.5rem; }
.u-ml-10 { margin-left: 2.5rem; }
.u-mt-12 { margin-top: 3rem; }
.u-mr-12 { margin-right: 3rem; }
.u-mb-12 { margin-bottom: 3rem; }
.u-ml-12 { margin-left: 3rem; }
.u-mt-16 { margin-top: 4rem; }
.u-mr-16 { margin-right: 4rem; }
.u-mb-16 { margin-bottom: 4rem; }
.u-ml-16 { margin-left: 4rem; }
.u-mt-20 { margin-top: 5rem; }
.u-mr-20 { margin-right: 5rem; }
.u-mb-20 { margin-bottom: 5rem; }
.u-ml-20 { margin-left: 5rem; }
.u-mt-24 { margin-top: 6rem; }
.u-mr-24 { margin-right: 6rem; }
.u-mb-24 { margin-bottom: 6rem; }
.u-ml-24 { margin-left: 6rem; }
.u-mt-32 { margin-top: 8rem; }
.u-mr-32 { margin-right: 8rem; }
.u-mb-32 { margin-bottom: 8rem; }
.u-ml-32 { margin-left: 8rem; }
.u-mt-40 { margin-top: 10rem; }
.u-mr-40 { margin-right: 10rem; }
.u-mb-40 { margin-bottom: 10rem; }
.u-ml-40 { margin-left: 10rem; }
.u-mt-48 { margin-top: 12rem; }
.u-mr-48 { margin-right: 12rem; }
.u-mb-48 { margin-bottom: 12rem; }
.u-ml-48 { margin-left: 12rem; }
.u-mt-56 { margin-top: 14rem; }
.u-mr-56 { margin-right: 14rem; }
.u-mb-56 { margin-bottom: 14rem; }
.u-ml-56 { margin-left: 14rem; }
.u-mt-64 { margin-top: 16rem; }
.u-mr-64 { margin-right: 16rem; }
.u-mb-64 { margin-bottom: 16rem; }
.u-ml-64 { margin-left: 16rem; }
.u-mt-auto { margin-top: auto; }
.u-mr-auto { margin-right: auto; }
.u-mb-auto { margin-bottom: auto; }
.u-ml-auto { margin-left: auto; }
.u-mt-px { margin-top: 1px; }
.u-mr-px { margin-right: 1px; }
.u-mb-px { margin-bottom: 1px; }
.u-ml-px { margin-left: 1px; }
.u--mt-1 { margin-top: -0.25rem; }
.u--mr-1 { margin-right: -0.25rem; }
.u--mb-1 { margin-bottom: -0.25rem; }
.u--ml-1 { margin-left: -0.25rem; }
.u--mt-2 { margin-top: -0.5rem; }
.u--mr-2 { margin-right: -0.5rem; }
.u--mb-2 { margin-bottom: -0.5rem; }
.u--ml-2 { margin-left: -0.5rem; }
.u--mt-3 { margin-top: -0.75rem; }
.u--mr-3 { margin-right: -0.75rem; }
.u--mb-3 { margin-bottom: -0.75rem; }
.u--ml-3 { margin-left: -0.75rem; }
.u--mt-4 { margin-top: -1rem; }
.u--mr-4 { margin-right: -1rem; }
.u--mb-4 { margin-bottom: -1rem; }
.u--ml-4 { margin-left: -1rem; }
.u--mt-5 { margin-top: -1.25rem; }
.u--mr-5 { margin-right: -1.25rem; }
.u--mb-5 { margin-bottom: -1.25rem; }
.u--ml-5 { margin-left: -1.25rem; }
.u--mt-6 { margin-top: -1.5rem; }
.u--mr-6 { margin-right: -1.5rem; }
.u--mb-6 { margin-bottom: -1.5rem; }
.u--ml-6 { margin-left: -1.5rem; }
.u--mt-8 { margin-top: -2rem; }
.u--mr-8 { margin-right: -2rem; }
.u--mb-8 { margin-bottom: -2rem; }
.u--ml-8 { margin-left: -2rem; }
.u--mt-10 { margin-top: -2.5rem; }
.u--mr-10 { margin-right: -2.5rem; }
.u--mb-10 { margin-bottom: -2.5rem; }
.u--ml-10 { margin-left: -2.5rem; }
.u--mt-12 { margin-top: -3rem; }
.u--mr-12 { margin-right: -3rem; }
.u--mb-12 { margin-bottom: -3rem; }
.u--ml-12 { margin-left: -3rem; }
.u--mt-16 { margin-top: -4rem; }
.u--mr-16 { margin-right: -4rem; }
.u--mb-16 { margin-bottom: -4rem; }
.u--ml-16 { margin-left: -4rem; }
.u--mt-20 { margin-top: -5rem; }
.u--mr-20 { margin-right: -5rem; }
.u--mb-20 { margin-bottom: -5rem; }
.u--ml-20 { margin-left: -5rem; }
.u--mt-24 { margin-top: -6rem; }
.u--mr-24 { margin-right: -6rem; }
.u--mb-24 { margin-bottom: -6rem; }
.u--ml-24 { margin-left: -6rem; }
.u--mt-32 { margin-top: -8rem; }
.u--mr-32 { margin-right: -8rem; }
.u--mb-32 { margin-bottom: -8rem; }
.u--ml-32 { margin-left: -8rem; }
.u--mt-40 { margin-top: -10rem; }
.u--mr-40 { margin-right: -10rem; }
.u--mb-40 { margin-bottom: -10rem; }
.u--ml-40 { margin-left: -10rem; }
.u--mt-48 { margin-top: -12rem; }
.u--mr-48 { margin-right: -12rem; }
.u--mb-48 { margin-bottom: -12rem; }
.u--ml-48 { margin-left: -12rem; }
.u--mt-56 { margin-top: -14rem; }
.u--mr-56 { margin-right: -14rem; }
.u--mb-56 { margin-bottom: -14rem; }
.u--ml-56 { margin-left: -14rem; }
.u--mt-64 { margin-top: -16rem; }
.u--mr-64 { margin-right: -16rem; }
.u--mb-64 { margin-bottom: -16rem; }
.u--ml-64 { margin-left: -16rem; }
.u--mt-px { margin-top: -1px; }
.u--mr-px { margin-right: -1px; }
.u--mb-px { margin-bottom: -1px; }
.u--ml-px { margin-left: -1px; }

@include media-breakpoint-up(md) {
  .u--ml-md-4 { margin-left: -1rem; }
  .u--ml-md-20 { margin-left: -5rem; }
  .u-mb-md-5 { margin-bottom: 1.25rem; }
  .u-mb-md-12 { margin-bottom: 3rem; }
  .u-my-md-10 { margin-top: 2.5rem; margin-bottom: 2.5rem; }
}
@include media-breakpoint-up(lg) {
  .u--ml-lg-4 { margin-left: -1rem; }
}