// Text Color
.u-text-transparent { color: transparent; }
.u-text-body { color: $body-color; }
.u-text-black { color: $black; }
.u-text-white { color: $white; }
.u-text-primary { color: $primary; }
.u-text-primary-dark { color: $blue-dark; }
.text-primary-dark { color: $blue-dark; }
.u-text-secondary { color: $secondary; }

.u-text-gray-500 { color: $gray-500; }
.u-text-blue-250 { color: $blue-250; }
.u-text-orange { color: $orange; }

// Text Decoration
.u-underline { text-decoration: underline; }
.u-line-through { text-decoration: line-through; }
.u-no-underline { text-decoration: none; }

// Text Transform
.u-uppercase { text-transform: uppercase; }
.u-lowercase { text-transform: lowercase; }
.u-capitalize { text-transform: capitalize; }
.u-normal-case { text-transform: none !important; }

// Font Size
.u-text-xs { font-size: 0.75rem; }
.u-text-sm { font-size: 0.875rem; }
.u-text-md { font-size: rem(15); }
.u-text-base { font-size: 1rem; }
.u-text-lg { font-size: 1.125rem; }
.u-text-xl { font-size: 1.25rem; }
.u-text-xxl { font-size: 1.375rem; }
.u-text-2xl { font-size: 1.5rem; }
.u-text-3xl { font-size: 2rem; }
.u-text-4xl { font-size: 2.5rem; }
.u-text-5xl { font-size: 3rem; }
.u-text-6xl { font-size: 4rem; }

// Font weight
.u-font-hairline { font-weight: 100; }
.u-font-thin { font-weight: 200; }
.u-font-light { font-weight: 300; }
.u-font-normal { font-weight: 400; }
.u-font-medium { font-weight: 500 !important; }
.u-font-semibold { font-weight: 600; }
.u-font-bold { font-weight: 700; }
.u-font-extrabold { font-weight: 800; }
.u-font-black { font-weight: 900; }

// Line height
.u-leading-none { line-height: 1; }
.u-leading-tight { line-height: 1.25; }
.u-leading-snug { line-height: 1.375; }
.u-leading-normal { line-height: 1.5; }
.u-leading-relaxed { line-height: 1.625; }
.u-leading-loose { line-height: 2; }
