a[target="_blank"]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".docx"]):not([href$=".xsd"]):not([href$=".xml"]):not([href$=".zip"]):not([href$=".xls"]):not([href$=".xlsx"]):not(.btn):not(.no-icon):after,
a[href$=".pdf"]:not(.btn):not(.card):after,
a[href$=".xsd"]:not(.btn):not(.card):after,
a[href$=".xml"]:not(.btn):not(.card):after,
a[href$=".xls"]:not(.btn):not(.card):after,
a[href$=".xlsx"]:not(.btn):not(.card):after,
a[href$=".docx"]:not(.btn):not(.card):after,
a[href$=".doc"]:not(.btn):not(.card):after,
a[href$=".zip"]:not(.btn):not(.card):after,
a[target="_blank"] .c-hero-title:after {
  margin-left: 5px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  font-size: 1rem;
}

// Link with external link icon
a[target="_blank"]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".docx"]):not([href$=".xsd"]):not([href$=".xml"]):not([href$=".zip"]):not([href$=".xls"]):not([href$=".xlsx"]):not(.btn):not(.no-icon):after, a[target="_blank"] .c-hero-title:after {
  content: "\f35d";
  font-weight: 900;
  font-size: 0.8rem;
}

// Link with PDF icon
a[href$=".pdf"]:not(.btn):not(.card):after {
  content: "\f1c1";
}

// Link with XLS icon
a[href$=".xsd"]:not(.btn):not(.card):after,
a[href$=".xml"]:not(.btn):not(.card):after,
a[href$=".xls"]:not(.btn):not(.card):after,
a[href$=".xlsx"]:not(.btn):not(.card):after {
  content: "\f1c3";
}

a[href$=".docx"]:not(.btn):not(.card):after,
a[href$=".doc"]:not(.btn):not(.card):after {
  content: "\f1c2";
}

a[href$=".zip"]:not(.btn):not(.card):after {
  content: "\f1c6";
}

.text-white a,
.text-primary a,
.text-dark a,
.text-white a {
  color: inherit;
}

.text-underline-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.align-chevron {
  display: inline;
  position: relative;
  top: -0.2em;
}