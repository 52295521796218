html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }

  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

body {
  position:relative;
}

h1 {
  font-weight: 700;

  @include media-breakpoint-down(md) {
    word-break: break-word;
  }
}

h1:focus, .h1:focus,
h2:focus, .h2:focus,
h3:focus, .h3:focus,
h4:focus, .h4:focus,
h5:focus, .h5:focus,
h6:focus, .h6:focus {
  outline: none;
}
