.c-site-footer
{
  margin-top: rem(100);
  a {
    color: $white;
    text-decoration: none !important;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}
