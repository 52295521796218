.language-nav {
    .nav-link.active {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            right: 0;
            left: 0;
            height: 2px;
            background-color: $primary;
        }
    }
}

.logo {
    img {
        height: 58px;
    }
}

.navbar-collapse {
    position: absolute;
    top: 82px; left: 0; right: 0;
    background: $white;
    z-index: $zindex-sticky;

    .nav-link {
        text-transform: uppercase;
    }
}

.navbar-light .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-active-bg);
}


.main-nav {
    .dropdown-toggle {
        display: flex;
        align-items: center;
        white-space: normal;

        &[aria-expanded="false"]:after {
            @include fonticon('\f078');
        }
        &[aria-expanded="true"]:after {
            @include fonticon('\f077');
        }
        &:after {
            border: 0;
            vertical-align: initial;
            margin-left: 0.5rem;
        }
    }

    .dropdown-item {
        font-weight: 500;
    }

    .active .dropdown-toggle,
    .dropdown-toggle[aria-expanded="true"],
    .nav-link.active {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 3px;
            background-color: $primary;
        }
    }
}

.secondary-nav {
    a {
        color: $body-color;
        text-decoration: none;
        position: relative;

        &.active {
            &:after {
                content: '';
                height: 2px;
                background-color: $primary;
                position: absolute;
                right: 0;
                bottom: -2px;
                left: 0;
            }
        }
    }
}

.language-select {
    padding: 1rem;
}

@include media-breakpoint-down(xs) {
    .logo {
        img {
            height: 42px;
        }
    }

    .navbar-collapse {
        top: 66px;
    }
}

@include media-breakpoint-down(md) {
    .navbar .btn-round { font-size: 1.4rem; }

    .navbar-collapse {
        box-shadow: 0 10px 30px -10px rgba(0,0,0,0.65);
    }

    .main-nav {
        padding: 0.5rem 1rem;

        .nav-link {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
        }

        .active .dropdown-toggle,
        .dropdown-toggle[aria-expanded="true"],
        .nav-link.active {
            font-weight: bold;

            &:before {
                width: 4px;
                height: auto;
                right: auto;
                top: 5px;
                bottom: 5px;
                left: -15px;
            }
            &:after {
                margin-left: auto;
            }
        }

        .dropdown-menu {
            border: 0;
            margin-top: 0;
            padding: 0;

            [class*="container"] {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .dropdown-item {
            padding-left: 1rem;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;

            &:hover, &.active {
                background-color: $blue-100;
                color: $body-color;
            }

            &.active {
                font-weight: bold;
            }
        }

        .nav-item:not(:last-child) {
            border-bottom: 1px solid $gray-400;
        }
    }

    .secondary-nav {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        background-color: $gray-200;

        a {
            padding: 0.8rem 0;

            &:not(:last-child) {
                border-bottom: 1px solid $gray-400;
            }

            &.active {
                font-weight: bold;

                &:after {
                    width: 4px;
                    height: auto;
                    right: auto;
                    top: 5px;
                    bottom: 5px;
                    left: -14px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar-collapse {
        position: static;
        background: transparent;
    }

    .main-nav {
        font-weight: bold;

        .active .dropdown-toggle,
        .dropdown-toggle[aria-expanded="true"],
        .nav-link.active {
            &:before {
                bottom: -8px;
            }
        }

        .dropup, .dropright, .dropdown, .dropleft {
            position: static;
            // display: flex;
        }

        .dropdown-menu {
            left: 0;
            right: 0;
            width: 100%;
            border-radius: 0;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            background-color: $blue-100;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
            border-color: #dadada;

            &.show {
                display: flex;
            }
        }

        .dropdown-item {
            width: calc(16.6% - 1rem);
            margin-right: 1rem;
            padding-left: 0;
            padding-right: 0;
            white-space: normal;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            font-size: rem(15);

            &.active, &:active, &:hover, &:focus {
                background-color: transparent;
                position: relative;
                color: $body-color;

                &:after {
                    content: '';
                    background-color: $primary;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }

        .nav-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-right: 1rem;
            height: 100%;
            align-items: center;
        }

        .nav-item:last-child .nav-link {
            margin-right: 0;
        }
    }

    .secondary-nav {
        a {
            font-size: rem(14);
        }
    }
}

@include media-breakpoint-up(xl) {
    .main-nav {
        .dropdown-item {
            width: calc(15% - 1.5rem);
            margin-right: 1.5rem;
            font-size: rem(15);
        }

        .nav-link {
            margin-right: 2.5rem;
        }
    }
}
