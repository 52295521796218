.c-bootstrap-modal-video
{
  .modal-dialog {
    max-width: 1024px;
    margin: 0 auto;
  }

  .modal-body {
    position: relative;
    padding: 0px;
  }

  .close {
    font-size: 2rem;
    font-weight: normal;
    text-shadow: none;
    color: #fff;
    opacity:1;
  }
}
