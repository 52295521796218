@import "~@elasticms/admin-menu/css/style";

#dump-modal {
  overflow:hidden;
  position:fixed;
  left: 0;
  bottom: 2rem;
  display: block;

  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

a.ems-external-link:not(.no-icon):after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin: 0 .35em;
  font-size: .8em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f35d";
}
