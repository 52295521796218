.stat-forms {
  h2, .h2 {
    font-size: em(22);
    color: $primary-darker;
  }
  .btn-link {
    color: $primary-darker;
  }
  a, .btn-outline-primary:not(:hover):not(:disabled):not(.disabled) {
    color: $primary-darker;
  }
  .input-group > label + .custom-select {
    margin-left: -1px;
  }
  .custom-select {
    min-width: 3.65rem;
  }
}