// Background Color
.u-bg-transparent { background-color: transparent; }
.u-bg-black { background-color: $black; }
.u-bg-gray-700 { background-color: $gray-700; }
.u-bg-white { background-color: $white; }
.u-bg-primary { background-color: $primary; }
.u-bg-secondary { background-color: $secondary; }
.u-bg-blue { background-color: $blue; }
.u-bg-blue-100 { background-color: $blue-100; }
.u-bg-blue-200 { background-color: $blue-200; }
.u-bg-blue-300 { background-color: $blue-300; }
