$collapse-pill-size: 12px;
$collapse-pill-size-sm: 10px;
$collapse-pill-thickness: 2px;

.collapse-wrapper, .target-collapse {
    border-bottom: $border-width solid $gray-300;
    .collapse-wrapper,.target-collapse {
        &:last-of-type {
            border-bottom: none;
        }
    }
    > [data-toggle="collapse"] {
        cursor: pointer;
        padding: 1rem 0;
        text-decoration: none;
        color: currentColor;

        &[aria-expanded="true"] {
            color: $primary;
            
            .collapse-pill {
                transform: rotate(45deg);
            }
        } 

        &[aria-expanded="false"], 
        &.collapsed {
            .collapse-pill {
                transform: rotate(0deg);
            }
        }
    }
}

.collapse-pill {
    display: block;
    transform: rotate(0deg);
    transition: $transition-base;
    flex: 0 0 $collapse-pill-size;
    width: $collapse-pill-size;
    height: $collapse-pill-size;
    margin-right: 1.5rem;
    margin-top: 6px;
    
    &:before,
    &:after {
        content: '';
        background-color: currentColor;
        position: absolute;
    }

    &:before {
        width: $collapse-pill-thickness;
        height: 100%;
        top: 0;
        left: calc(50% - #{$collapse-pill-thickness / 2});
    }

    &:after {
        height: $collapse-pill-thickness;
        width: 100%;
        left: 0;
        top: calc(50% - #{$collapse-pill-thickness / 2});
    }
}