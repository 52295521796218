#form-ems {
    label {
        font-size: rem(18);
        font-weight: 500;
        display: block;

        &.required:after {
            content: '*';
            color: $red;
            margin-left: 0.25rem;
        }
    }

    .textarea {
        height: 200px;
    }
}