.table {
    caption {
        caption-side: top;
    }

    border: 1px solid $border-color;
}

.table-responsive {
    margin-bottom: 2rem;
}

.table-bordered {
    thead {
      th {
        border-color: $primary;
        background: $primary;
        color: $white;
      }
    }
    tbody {
      th {
        background: $blue-100;
        color: darken($primary, 4);
        border-color: $border-color;
      }
    }
}