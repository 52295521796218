#cookiesBanner {
  display: none;
  background-color: $blue;
  color: white;
  font-size:rem(14);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $zindex-fixed;
  padding: 1em 0;
  box-shadow: 0 0 1.875rem rgb(0 0 0 / 20%);
  &.active {
    display: block;
  }
  .btn-outline-light {
    font-size:rem(14);
    color: white;
    &:not(:disabled):not(.disabled):active {
      background-color: darken($blue, 5%);
    }
  }
}

