.target-collapse {
    > [data-toggle="collapse"] {
        display: flex;
        align-items: flex-start;
        border: 0;
    }

    // smaller pill in h4
    h4, .h4 {
        .collapse-pill {
            width: $collapse-pill-size-sm;
            height: $collapse-pill-size-sm;
        }
    }

    // fix ul pills cut during animation
    > *:last-child {
        ul {
            padding-left: 1.2rem;

            &.checklist {
                padding-left: 0;
            }

            ul:not(.checklist) {
                padding-left: 2.5rem;
                margin-top: 1rem;
            }
        }
    }

    & + .target-collapse h3 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}