.c-image-with-video-thumb {
  position: relative;
  display: block;
  margin: 0;
  line-height: 0;

  &::before {
    content: '';
    position: absolute;
    top:0; bottom: 0; left: 0; right: 0;
    background: url('#{$img-path}/icon--play.png') no-repeat center center;
    opacity: .8;
    transition: opacity .2s ease-in;
    z-index: 1;
  }

  &:hover
  {
    &::before { opacity: 1;}
  }

  &.c-image-with-video-thumb-overshadowed {
    &::before {
      content: '';
      position: absolute;
      top:0; bottom: 0; left: 0; right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 0;
    }
  }
}


.c-image-with-browser {
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 1.25rem;
    background-color: #f4f4f4;
    border: 0.063rem solid #ececec;
    border-bottom: none;
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &::after {
    content:'•••';
    position: absolute;
    top: 3px; left: 6px;
    color: #c1c1c1;
    letter-spacing: 0.06rem;
    font-size: 0.6rem;
  }

  img {
    border: 1px solid #ececec;
  }
}
