#searchModal {
    // .modal-dialog,
    // .modal-content {
    //     position: static !important;
    // }

    // .modal-header {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: 0;
    // }
    .modal-header {
        border: 0;

        .close {
            color: $white;
            text-shadow: none;
            opacity: 1;
            font-size: 1.25rem;
        }
    }

    .modal-dialog-centered {
        align-items: stretch;
    }

    .modal-content {
        background-color: $primary;
        border: 0;
    }

    .search-group {
        .form-control {
            color: $white;
            border-color: $white;
            font-size: rem(24);

            &::placeholder {
                color: $white !important;
            }
            &:-ms-input-placeholder {
                color: $white !important;
            }
            &::-ms-input-placeholder {
                color: $white !important;
            }
        }
        .btn {
            color: $white;
            border-bottom: 1px solid $white;
            font-size: rem(24);
        }
    }
}

.search-modal {
    .modal-backdrop {
        background-color: $primary;
        
        &.show {
            opacity: 1;
        }
    }
}