@mixin fonticon($icon, $fw:900, $font:'Font Awesome 5 Free') {
	content: fa-content($icon);
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	font-family: $font;
	font-weight: $fw;
}

// FontAwesome
@mixin faw-icon($fa-icon,$size:16,$fw: 900) {
  font-family: 'Font Awesome 5 Free';
  content: fa-content($fa-icon);
  @include fa-icon;
  font-size: em($size);
  font-weight: $fw;
}