.wysiwyg-content {
    h1, .h1 {
        font-size: rem(40);
        font-weight: 500;
    }
    h2, .h2 {
        font-size: rem(32);
        font-weight: 500;
        color: $primary;

        &:not(:first-child) {
            margin-top: 3rem;
        }
        + h3:not(:first-child) {
            margin-top: 1rem;
        }
    }
    h3, .h3 {
        font-size: rem(26);
        font-weight: 500;
        color: $gray-500;

        &:not(:first-child) {
            margin-top: 2.5rem;
        }
    }
    h2 + h3 {

    }
    h4, .h4 {
        font-size: rem(20);
        font-weight: 500;
        color: $primary;

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    .target-collapse [data-toggle="collapse"] {
        h1, h2, h3, h4 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .target-collapse + *:not(.target-collapse) {
        margin-top: 2rem;
    }

    .target-collapse:last-child {
        margin-bottom: 2rem;
    }

    ul, ol {
        padding-left: 1.2rem;

        ul, ol {
            margin-bottom: 0.5rem;
            margin-top: 0.25rem;
        }
    }
}
