.c-out-of-box__wrapper { overflow-x: hidden; }

.c-out-of-box {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0; bottom: 0;
    width: 100vw;
    background-color: inherit;
    z-index: 0;
  }
}

.c-out-of-box--to-right::after { right: -100vw;}
.c-out-of-box--to-left::after { left: -100vw;}
