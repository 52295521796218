// Min Height
.u-min-h-full { min-height: 100%; }
.u-min-h-screen { min-height: 100vh; }

.minw-none {
    min-width: none;
}
.maxw-none {
    max-width: none;
}

// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .w#{$infix}-auto            { width: auto !important; }
        .w#{$infix}-66             { width: 66%; }
    }
}
