.collapse-wrapper {
    > [data-toggle="collapse"] {
        display: block;
        h2, .h2, h3, .h3, h4, .h4 {
            display: flex;
            align-items: center;
            margin-top: .5em !important;
            margin-bottom: .5em !important;
        }
        h2, .h2 {
            color: $gray-500;
            &:after {
                content: none !important;
            }
        }
        h3, .h3 {
            color: $body-color;
        }

        .collapse-pill {
            margin-top: 0;
        }

        &[aria-expanded="true"] {
            > h2, > .h2, > h3, > .h3, > h4, > .h4 {
                color: $primary;
            }
        }
    }
}