.c-timeline
{
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: -167px;
    width: 694px;
    height: 234px;
    // background-image: url(../images/bg/home_pattern.png);
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(lg) {
      height: 190px;
      top: -123px;
      background-size: contain;
    }

    @include media-breakpoint-down(sm) {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
    }
  }

  .swiper-container {
    width: 100%;
    overflow: initial;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition-base;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .swiper-container-initialized {
    .swiper-slide {
      opacity: 1;
    }

    .swiper-button-next, .swiper-button-prev {
      display: flex;
    }
  }

  .swiper-notification {
    display: none !important;
  }

  .timeline {
    margin-top: 134px;
    margin-bottom: 134px;

    .swiper-slide {
      width: 634px;
      max-width: 100%;

      &:nth-child(1), &:nth-child(3) {
        .event {
          transform: translateY(-65%);

          &:nth-child(even) {
            justify-content: flex-end;
            transform: translateY(65%);
          }
        }
      }

      &:nth-child(2) {
        margin-top: rem(100);

        .event {
          &:nth-child(odd) {
            justify-content: flex-end;
            transform: translateY(65%);
          }
          &:nth-child(even) {
            justify-content: flex-start;
            transform: translateY(-65%);
          }
        }
      }

      &:nth-child(3) {
        margin-bottom: rem(100);
      }

      &.slide-now {
        .event:not(:first-child) {
          transform: translate(-70px, -65%);

          &:nth-child(even) {
            justify-content: flex-end;
            transform: translate(-92%, 65%);
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 50px;
      height: 50px;
      background: rgba($primary, 0.7);
      border: 1px solid $light;
      border-radius: 50%;
      line-height: calc(#{rem(16)} - 2px);
      z-index: 1;
      opacity: 1;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.swiper-button-disabled {
        opacity: 0;
      }

      &:after {
        color: $light;
        font-size: rem(16);
        text-align: center;
      }
    }

    .swiper-button-next {
      right: 0;
      padding-left: 3px;

      &:after {
        @include fonticon('\f054', 900);
      }
    }

    .swiper-button-prev {
      left: 0;
      padding-right: 3px;

      &:after {
        @include fonticon('\f053', 900);
      }
    }

    .events {
      top: 10%;
      bottom: 10%;
    }
  }

  @include media-breakpoint-down(xs) {
    .timeline {
      margin-top: 0;
      margin-bottom: 0;

      .swiper-wrapper {
        flex-wrap: wrap;
      }

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        opacity: 1;

        & + .swiper-slide {
          margin-top: rem(40) !important;
          margin-bottom: 0 !important;
        }
      }

      .events {
        position: relative;
        margin-top: rem(20);
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100% - #{rem(20)});
        margin-left: rem(20);

        &:before {
          content: '';
          position: absolute;
          top: -80px;
          left: 0;
          bottom: 0;
          width: 1px;
          background-color: $dark;
        }

        .event {
          transform: none !important;

          &:before {
            display: none;
          }

          & + .event {
            margin-top: rem(30);
          }

          .event-time {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
