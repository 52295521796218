@import '~swiper/swiper-bundle.css';

#hero-swiper {
    .swiper-slide {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;

        &:not(.is-youtube):before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($gray-700, 0.1);
            z-index: 1;
        }

        .slide-text {
            position: relative;
            z-index: 2;
        }
    }
}

.swiper-pagination-bullet {
  background: white;
  width: 12px;
  height: 12px;
  opacity: 1;
  margin-right: 0.5rem;
}

.swiper-pagination-bullet-active {
  background: white;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    background: $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    overflow: hidden;
  }
}

#barometer-swiper {
    .swiper-slide {
        width: auto;
    }

    &.swiper-container-free-mode {
        &:before,
        &:after {
            content: '';
            transition: $transition-base;
            height: 100%;
            width: 25px;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 10;
            opacity: 0;

            @include media-breakpoint-up(md) {
                width: 150px;
            }
        }

        &:before {
            background: linear-gradient(to right, #fff, rgba(255,255,255,0));
            left: 0;
        }
        &:after {
            background: linear-gradient(to left, #fff, rgba(255,255,255,0));
            right: 0;
        }

        &.mask-beginning:before {
            opacity: 1;
        }
        &.mask-end:after {
            opacity: 1;
        }
    }
}

.swiper-custom-navigation {
    position: relative;

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 60%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        background-color: $gray-400;
        z-index: 0;
    }

    .btn.disabled {
        opacity: 1;
        background-color: $gray-400;
        border-color: $gray-400;

        &.btn-round {
            width: 41px;
            height: 41px;
            line-height: 41px;
            margin-left: calc(1rem + 2px);
            margin-right: calc(1rem + 2px);
        }
    }

    .swiper-next {
        padding: 0 0 0 0.1rem;
    }
    .swiper-prev {
        padding: 0 0.1rem 0 0;
    }

    .swiper-next,
    .swiper-prev {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
