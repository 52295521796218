.highcharts-data-table {
  table {
    @extend .table;
  }
}

.tab-pane {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($white,.75);
    z-index: 1;
  }
}