.c-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.c-hero-title {
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.2;
}

.c-hero-description {
  font-size: rem(32);
  font-weight: 700;
  line-height: 1.2;
  word-break: break-word;
}

.c-hero-box {
  width: calc(100% - 16px);
  background: $primary;
  border-radius: 20px 0 0 20px;
  padding: 1.75rem 1rem 1.5rem 2rem;
  position: relative;
  top: -32px;
  z-index: $zindex-dropdown;
}

@include media-breakpoint-down(sm) {
  .c-hero-slide {
    background-image: none !important;
  }
}

@include media-breakpoint-up(md) {
  .c-hero {
    height: calc(100vh - 200px - 64px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .c-hero-title {
    font-size: rem(20);
  }

  .c-hero-visual {
    img {
      display: none;
    }
  }

  .c-hero-box {
    position: absolute;
    width: 60%;
    top: auto;
    right: 0;
    bottom: -32px;
    padding-right: 0;
  }

  .c-hero-box__container {
    max-width: calc((720px / 100) * 60);
  }
}

@include media-breakpoint-up(lg) {
  .c-hero-box__container {
    max-width: calc((960px / 100) * 60);
  }
}

@include media-breakpoint-up(xl) {
  .c-hero-box__container {
    max-width: calc((1140px / 100) * 60);
  }
}
