.nav-tabs-wrapper {
  border-bottom: 1px solid $border-color;
}

.nav-tabs {
  .nav-item {
    margin-right: 2rem;
    @include media-breakpoint-up(md) {
      margin-right: rem(60);
    }
  }
  .nav-link {
    padding: 1rem 0;
    border-bottom: 4px solid transparent;
    background-color: transparent;
    &.active  {
      border-color: $primary;
    }
  }
}