// IE10 and IE11   (Method 2)
// ---------------------------------
@media all and (-ms-high-contrast:none){
  // fix jumpy background fixed
  html{ overflow: hidden; height: 100%; }
  body{ overflow: auto; height: 100%; }
  .c-hero { height:100vh; }
  img { flex-shrink: 0; } // fix img stretch in flex box parent
}
// ---------------------------------

/*----MS Edge Browser CSS Start----*/
@supports (-ms-ime-align:auto) and (display:-ms-grid) {
  // fix jumpy background fixed
  html{ overflow: hidden; height: 100%; }
  body{ overflow: auto; height: 100%; }

  // hide bullet on Edge
  ul.nav,
  ul.list-group,
  ul.list-unstyled,
  ul.dropdown-menu {
    list-style: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
    list-style-type: none;
  }

  // Weird bug in the main nav
  // ul.navbar-nav {
  //   list-style: none;
  //   // list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
  //   list-style-type: none;
  // }
}
/*----MS Edge Browser CSS End----*/
