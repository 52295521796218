@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';

// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Regular");
// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Thin", 300);
// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Regular", 400);
// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Medium", 500);
// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Bold", 700);
// @include fontface("WesFYW03", "#{$fonts-path}/Wes FY W03 Black", 800);

@include fontfaceWoff("Wes FY", "#{$fonts-path}/wesfy-medium", 500);
@include fontfaceWoff("Wes FY", "#{$fonts-path}/wesfy-bold", 700);