.u-cursor-auto { cursor: auto; }
.u-cursor-default { cursor: default; }
.u-cursor-pointer { cursor: pointer; }
.u-cursor-wait { cursor: wait; }
.u-cursor-text { cursor: text; }
.u-cursor-move { cursor: move; }
.u-cursor-initial { cursor: initial; }

.u-outline-none { outline: 0; }

.u-pointer-events-none { pointer-events: none; }
.u-pointer-events-auto { pointer-events: auto; }
