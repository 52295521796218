.a-popHover
{
    position: relative;
    z-index: 1;
    transition: box-shadow .2s ease-in, transform .2s ease-in;

    &:hover {
        box-shadow: 0 0 1.875rem rgba(36, 127, 141, 0.2);
        transition: box-shadow .25s ease-out, transform .25s ease-out;
    }
}

@include media-breakpoint-up(lg)
{
    .a-popHover
    {
        &:hover {
            transform: scale(1.02);
        }
    }
}
