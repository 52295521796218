.u-p-1 { padding: 0.25rem; }
.u-p-2 { padding: 0.5rem; }
.u-p-3 { padding: 0.75rem; }
.u-p-4 { padding: 1rem; }
.u-p-5 { padding: 1.25rem; }
.u-p-6 { padding: 1.5rem; }
.u-p-8 { padding: 2rem; }
.u-p-10 { padding: 2.5rem; }
.u-p-12 { padding: 3rem; }
.u-p-16 { padding: 4rem; }
.u-p-20 { padding: 5rem; }
.u-p-24 { padding: 6rem; }
.u-p-32 { padding: 8rem; }
.u-p-40 { padding: 10rem; }
.u-p-48 { padding: 12rem; }
.u-p-56 { padding: 14rem; }
.u-p-64 { padding: 16rem; }
.u-p-px { padding: 1px; }
.u-py-0 { padding-top: 0; padding-bottom: 0; }
.u-px-0 { padding-left: 0; padding-right: 0; }
.u-py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.u-px-1 { padding-left: 0.25rem; padding-right: 0.25rem; }
.u-py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.u-px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.u-py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem; }
.u-px-3 { padding-left: 0.75rem; padding-right: 0.75rem; }
.u-py-4 { padding-top: 1rem; padding-bottom: 1rem; }
.u-px-4 { padding-left: 1rem; padding-right: 1rem; }
.u-py-5 { padding-top: 1.25rem; padding-bottom: 1.25rem; }
.u-px-5 { padding-left: 1.25rem; padding-right: 1.25rem; }
.u-py-6 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.u-px-6 { padding-left: 1.5rem; padding-right: 1.5rem; }
.u-py-7 { padding-top: 1.75rem; padding-bottom: 1.75rem; }
.u-px-7 { padding-left: 1.75rem; padding-right: 1.75rem; }
.u-py-8 { padding-top: 2rem; padding-bottom: 2rem; }
.u-px-8 { padding-left: 2rem; padding-right: 2rem; }
.u-py-10 { padding-top: 2.5rem; padding-bottom: 2.5rem; }
.u-px-10 { padding-left: 2.5rem; padding-right: 2.5rem; }
.u-py-9 { padding-top: 2.25rem; padding-bottom: 2.25rem; }
.u-px-9 { padding-left: 2.25rem; padding-right: 2.25rem; }
.u-py-12 { padding-top: 3rem; padding-bottom: 3rem; }
.u-px-12 { padding-left: 3rem; padding-right: 3rem; }
.u-py-16 { padding-top: 4rem; padding-bottom: 4rem; }
.u-px-16 { padding-left: 4rem; padding-right: 4rem; }
.u-py-20 { padding-top: 5rem; padding-bottom: 5rem; }
.u-px-20 { padding-left: 5rem; padding-right: 5rem; }
.u-py-24 { padding-top: 6rem; padding-bottom: 6rem; }
.u-px-24 { padding-left: 6rem; padding-right: 6rem; }
.u-py-32 { padding-top: 8rem; padding-bottom: 8rem; }
.u-px-32 { padding-left: 8rem; padding-right: 8rem; }
.u-py-40 { padding-top: 10rem; padding-bottom: 10rem; }
.u-px-40 { padding-left: 10rem; padding-right: 10rem; }
.u-py-48 { padding-top: 12rem; padding-bottom: 12rem; }
.u-px-48 { padding-left: 12rem; padding-right: 12rem; }
.u-py-56 { padding-top: 14rem; padding-bottom: 14rem; }
.u-px-56 { padding-left: 14rem; padding-right: 14rem; }
.u-py-64 { padding-top: 16rem; padding-bottom: 16rem; }
.u-px-64 { padding-left: 16rem; padding-right: 16rem; }
.u-py-px { padding-top: 1px; padding-bottom: 1px; }
.u-px-px { padding-left: 1px; padding-right: 1px; }
.u-pt-0 { padding-top: 0; }
.u-pr-0 { padding-right: 0; }
.u-pb-0 { padding-bottom: 0; }
.u-pl-0 { padding-left: 0; }
.u-pt-1 { padding-top: 0.25rem; }
.u-pr-1 { padding-right: 0.25rem; }
.u-pb-1 { padding-bottom: 0.25rem; }
.u-pl-1 { padding-left: 0.25rem; }
.u-pt-2 { padding-top: 0.5rem; }
.u-pr-2 { padding-right: 0.5rem; }
.u-pb-2 { padding-bottom: 0.5rem; }
.u-pl-2 { padding-left: 0.5rem; }
.u-pt-3 { padding-top: 0.75rem; }
.u-pr-3 { padding-right: 0.75rem; }
.u-pb-3 { padding-bottom: 0.75rem; }
.u-pl-3 { padding-left: 0.75rem; }
.u-pt-4 { padding-top: 1rem; }
.u-pr-4 { padding-right: 1rem; }
.u-pb-4 { padding-bottom: 1rem; }
.u-pl-4 { padding-left: 1rem; }
.u-pt-5 { padding-top: 1.25rem; }
.u-pr-5 { padding-right: 1.25rem; }
.u-pb-5 { padding-bottom: 1.25rem; }
.u-pl-5 { padding-left: 1.25rem; }
.u-pt-6 { padding-top: 1.5rem; }
.u-pr-6 { padding-right: 1.5rem; }
.u-pb-6 { padding-bottom: 1.5rem; }
.u-pl-6 { padding-left: 1.5rem; }
.u-pt-8 { padding-top: 2rem; }
.u-pr-8 { padding-right: 2rem; }
.u-pb-8 { padding-bottom: 2rem; }
.u-pl-8 { padding-left: 2rem; }
.u-pt-9 { padding-top: 2.25rem; }
.u-pr-9 { padding-right: 2.25rem; }
.u-pb-9 { padding-bottom: 2.25rem; }
.u-pl-9 { padding-left: 2.25rem; }
.u-pt-10 { padding-top: 2.5rem; }
.u-pr-10 { padding-right: 2.5rem; }
.u-pb-10 { padding-bottom: 2.5rem; }
.u-pl-10 { padding-left: 2.5rem; }
.u-pt-12 { padding-top: 3rem; }
.u-pr-12 { padding-right: 3rem; }
.u-pb-12 { padding-bottom: 3rem; }
.u-pl-12 { padding-left: 3rem; }
.u-pt-16 { padding-top: 4rem; }
.u-pr-16 { padding-right: 4rem; }
.u-pb-16 { padding-bottom: 4rem; }
.u-pl-16 { padding-left: 4rem; }
.u-pt-20 { padding-top: 5rem; }
.u-pr-20 { padding-right: 5rem; }
.u-pb-20 { padding-bottom: 5rem; }
.u-pl-20 { padding-left: 5rem; }
.u-pt-24 { padding-top: 6rem; }
.u-pr-24 { padding-right: 6rem; }
.u-pb-24 { padding-bottom: 6rem; }
.u-pl-24 { padding-left: 6rem; }
.u-pt-32 { padding-top: 8rem; }
.u-pr-32 { padding-right: 8rem; }
.u-pb-32 { padding-bottom: 8rem; }
.u-pl-32 { padding-left: 8rem; }
.u-pt-40 { padding-top: 10rem; }
.u-pr-40 { padding-right: 10rem; }
.u-pb-40 { padding-bottom: 10rem; }
.u-pl-40 { padding-left: 10rem; }
.u-pt-48 { padding-top: 12rem; }
.u-pr-48 { padding-right: 12rem; }
.u-pb-48 { padding-bottom: 12rem; }
.u-pl-48 { padding-left: 12rem; }
.u-pt-56 { padding-top: 14rem; }
.u-pr-56 { padding-right: 14rem; }
.u-pb-56 { padding-bottom: 14rem; }
.u-pl-56 { padding-left: 14rem; }
.u-pt-64 { padding-top: 16rem; }
.u-pr-64 { padding-right: 16rem; }
.u-pb-64 { padding-bottom: 16rem; }
.u-pl-64 { padding-left: 16rem; }
.u-pt-px { padding-top: 1px; }
.u-pr-px { padding-right: 1px; }
.u-pb-px { padding-bottom: 1px; }
.u-pl-px { padding-left: 1px; }

@include media-breakpoint-up(md) {
  .u-pl-md-16 { padding-left: 4rem; }
  .u-pl-md-20 { padding-left: 5rem; }
  
  .u-py-md-10 { padding-top: 2.5rem; padding-bottom: 2.5rem; }
  .u-py-md-12 { padding-top: 3rem; padding-bottom: 3rem; }
  .u-py-md-16 { padding-top: 4rem; padding-bottom: 4rem; }
}
@include media-breakpoint-up(lg) {
  .u-pr-lg-8 { padding-right: 2rem; }
  .u-pl-lg-8 { padding-left: 2rem; }
}