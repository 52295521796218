.side-menu {
    .nav-item {
        border-top: 1px solid $gray-400;

        &.active, &.active + .nav-item {
            border-top: 0;
        }

        &.active {
            background: $blue-100;
            
            .nav-link {
                color: $body-color;
            }
        }

        &:last-child {
            border-bottom: 1px solid $gray-400;
        }
    }

    .nav-link {
        color: $gray-500;
        padding: 1rem;
    }
}