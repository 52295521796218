.c-timeline-modal
{
  .swiper-container {
    overflow: hidden;
  }

  [data-dismiss="modal"] {
    color: white;
    border: 0;
    opacity: 1;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .swiper-slide {
    width: 100%;
    background: none;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .events {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      background-color: rgba($primary, 0.95);
      padding: 3rem;
      width: 100%;
      max-width: 446px;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0, 0.25);
    }

    .event {
      justify-content: flex-end;
      padding-left: rem(20);
      flex: 1;
      max-width: 350px;
      cursor: auto;

      &:before {
        background-color: $white;
        bottom: -3rem;
        top: 0.1rem;
      }

      .event-title, .event-time, .event-description {
        color: white;
      }
    }
  }

  .modal-content {
    background: none;
    border: 0;
  }

  .modal-header {
    border-bottom: 0;
  }
  .modal-footer {
    border-top: 0;
  }
}

.s-page-history:not(.search-modal) .modal-backdrop {
  background: none;
}

.events {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding-left: 20px;
}

.event {
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  padding-left: rem(10);
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: 0;
    bottom: 0.5rem;
    width: 1px;
    background-color: $dark;
  }
}

.event-time {
  font-size: rem(25);
  color: $primary;

  @include media-breakpoint-down(sm) {
    font-size: rem(20);
  }
}

.event-title {
  font-size: rem(20);
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    font-size: rem(16);
  }
}

.event-description {
  margin-top: rem(30);
  font-size: rem(16);
  margin-bottom: 0;
}
