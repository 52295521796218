.light {
    &.light-top-left {
        background-image: radial-gradient(at top left, rgba(255, 255, 255, 0.5), transparent 50%);
    }
    &.light-bottom-right {
        background-image: radial-gradient(at bottom right, rgba(255, 255, 255, 0.5), transparent 50%);
    }
}

.dark-light {
    &.light-top-left {
        background-image: radial-gradient(at top left, rgba(0, 0, 0, 0.5), transparent 50%);
    }
    &.light-bottom-right {
        background-image: radial-gradient(at bottom right, rgba(0, 0, 0, 0.5), transparent 50%);
    }
}