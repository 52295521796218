.form-control:focus {
    outline: initial;
}

.search-group {
    .form-control {
        border-left: 0;
        border-top: 0;
        border-right: 0;
        border-radius: 0;
        background-color: transparent;
        color: $body-color;
        border-color: $body-color;
        font-size: rem(20);
        padding: $input-btn-padding-y $input-btn-padding-x;
        height: auto;
        padding-left: 0;
    }

    .btn {
        font-weight: bold;
        color: $primary;
        border-bottom: 1px solid $body-color;
        border-radius: 0;
        padding-right: 0;
        font-size: rem(20);
    }
}