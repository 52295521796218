// Border width
.u-border-0 { border-width: 0; }
.u-border-2 { border-width: 2px; }
.u-border-4 { border-width: 4px; }
.u-border-8 { border-width: 8px; }
.u-border { border-width: 1px; }
.u-border-t-0 { border-top-width: 0; }
.u-border-r-0 { border-right-width: 0; }
.u-border-b-0 { border-bottom-width: 0; }
.u-border-l-0 { border-left-width: 0; }
.u-border-t-1 { border-top-width: 1px; }
.u-border-r-1 { border-right-width: 1px; }
.u-border-b-1 { border-bottom-width: 1px; }
.u-border-l-1 { border-left-width: 1px; }
.u-border-t-2 { border-top-width: 2px; }
.u-border-r-2 { border-right-width: 2px; }
.u-border-b-2 { border-bottom-width: 2px; }
.u-border-l-2 { border-left-width: 2px; }
.u-border-t-4 { border-top-width: 4px; }
.u-border-r-4 { border-right-width: 4px; }
.u-border-b-4 { border-bottom-width: 4px; }
.u-border-l-4 { border-left-width: 4px; }
.u-border-t-8 { border-top-width: 8px; }
.u-border-r-8 { border-right-width: 8px; }
.u-border-b-8 { border-bottom-width: 8px; }
.u-border-l-8 { border-left-width: 8px; }
.u-border-t { border-top-width: 1px; }
.u-border-r { border-right-width: 1px; }
.u-border-b { border-bottom-width: 1px; }
.u-border-l { border-left-width: 1px; }

// Border Style
.u-border-solid { border-style: solid; }
.u-border-dashed { border-style: dashed; }
.u-border-dotted { border-style: dotted; }
.u-border-none { border-style: none; }

// Border Color
.u-border-transparent { border-color: transparent; }
.u-border-black { border-color: $black; }
.u-border-white { border-color: $white; }
.u-border-primary { border-color: $primary; }
.u-border-secondary { border-color: $secondary; }
.u-border-blue-100 { border-color: $blue-100; }
.u-border-blue-400 { border-color: $blue-400; }
.u-border-blue-200 { border-color: $blue-200; }
.u-border-blue-300 { border-color: $blue-300; }
.u-border-blue-400 { border-color: $blue-400; }
.u-border-blue-500 { border-color: $blue-500; }
.u-border-blue-600 { border-color: $blue-600; }
.u-border-blue-700 { border-color: $blue-700; }
.u-border-blue-dark { border-color: $blue-dark; }

// Border Radius

.u-rounded-none { border-radius: 0; }
.u-rounded-sm { border-radius: $radius-sm; }
.u-rounded { border-radius: $radius; }
.u-rounded-md { border-radius: $radius-md; }
.u-rounded-lg { border-radius: $radius-lg; }
.u-rounded-xl { border-radius: $radius-xl; }
.u-rounded-full { border-radius: 9999px; }
.u-rounded-t-none { border-top-left-radius: 0; border-top-right-radius: 0; }
.u-rounded-r-none { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.u-rounded-b-none { border-bottom-right-radius: 0; border-bottom-left-radius: 0; }
.u-rounded-l-none { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.u-rounded-t-sm { border-top-left-radius: $radius-sm; border-top-right-radius: $radius-sm; }
.u-rounded-r-sm { border-top-right-radius: $radius-sm; border-bottom-right-radius: $radius-sm; }
.u-rounded-b-sm { border-bottom-right-radius: $radius-sm; border-bottom-left-radius: $radius-sm; }
.u-rounded-l-sm { border-top-left-radius: $radius-sm; border-bottom-left-radius: $radius-sm; }
.u-rounded-t { border-top-left-radius: $radius; border-top-right-radius: $radius; }
.u-rounded-r { border-top-right-radius: $radius; border-bottom-right-radius: $radius; }
.u-rounded-b { border-bottom-right-radius: $radius; border-bottom-left-radius: $radius; }
.u-rounded-l { border-top-left-radius: $radius; border-bottom-left-radius: $radius; }
.u-rounded-t-md { border-top-left-radius: $radius-md; border-top-right-radius: $radius-md; }
.u-rounded-r-md { border-top-right-radius: $radius-md; border-bottom-right-radius: $radius-md; }
.u-rounded-b-md { border-bottom-right-radius: $radius-md; border-bottom-left-radius: $radius-md; }
.u-rounded-l-md { border-top-left-radius: $radius-md; border-bottom-left-radius: $radius-md; }
.u-rounded-t-lg { border-top-left-radius: $radius-lg; border-top-right-radius: $radius-lg; }
.u-rounded-r-lg { border-top-right-radius: $radius-lg; border-bottom-right-radius: $radius-lg; }
.u-rounded-b-lg { border-bottom-right-radius: $radius-lg; border-bottom-left-radius: $radius-lg; }
.u-rounded-l-lg { border-top-left-radius: $radius-lg; border-bottom-left-radius: $radius-lg; }
.u-rounded-t-xl { border-top-left-radius: $radius-xl; border-top-right-radius: $radius-xl; }
.u-rounded-r-xl { border-top-right-radius: $radius-xl; border-bottom-right-radius: $radius-xl; }
.u-rounded-b-xl { border-bottom-right-radius: $radius-xl; border-bottom-left-radius: $radius-xl; }
.u-rounded-l-xl { border-top-left-radius: $radius-xl; border-bottom-left-radius: $radius-xl; }
.u-rounded-t-full { border-top-left-radius: 9999px; border-top-right-radius: 9999px; }
.u-rounded-r-full { border-top-right-radius: 9999px; border-bottom-right-radius: 9999px; }
.u-rounded-b-full { border-bottom-right-radius: 9999px; border-bottom-left-radius: 9999px; }
.u-rounded-l-full { border-top-left-radius: 9999px; border-bottom-left-radius: 9999px; }
.u-rounded-tl-none { border-top-left-radius: 0; }
.u-rounded-tr-none { border-top-right-radius: 0; }
.u-rounded-br-none { border-bottom-right-radius: 0; }
.u-rounded-bl-none { border-bottom-left-radius: 0; }
.u-rounded-tl-sm { border-top-left-radius: $radius-sm; }
.u-rounded-tr-sm { border-top-right-radius: $radius-sm; }
.u-rounded-br-sm { border-bottom-right-radius: $radius-sm; }
.u-rounded-bl-sm { border-bottom-left-radius: $radius-sm; }
.u-rounded-tl { border-top-left-radius: $radius; }
.u-rounded-tr { border-top-right-radius: $radius; }
.u-rounded-br { border-bottom-right-radius: $radius; }
.u-rounded-bl { border-bottom-left-radius: $radius; }
.u-rounded-tl-md { border-top-left-radius: $radius-md; }
.u-rounded-tr-md { border-top-right-radius: $radius-md; }
.u-rounded-br-md { border-bottom-right-radius: $radius-md; }
.u-rounded-bl-md { border-bottom-left-radius: $radius-md; }
.u-rounded-tl-lg { border-top-left-radius: $radius-lg; }
.u-rounded-tr-lg { border-top-right-radius: $radius-lg; }
.u-rounded-br-lg { border-bottom-right-radius: $radius-lg; }
.u-rounded-bl-lg { border-bottom-left-radius: $radius-lg; }
.u-rounded-tl-xl { border-top-left-radius: $radius-xl; }
.u-rounded-tr-xl { border-top-right-radius: $radius-xl; }
.u-rounded-br-xl { border-bottom-right-radius: $radius-xl; }
.u-rounded-bl-xl { border-bottom-left-radius: $radius-xl; }
.u-rounded-tl-full { border-top-left-radius: 9999px; }
.u-rounded-tr-full { border-top-right-radius: 9999px; }
.u-rounded-br-full { border-bottom-right-radius: 9999px; }
.u-rounded-bl-full { border-bottom-left-radius: 9999px; }


@include media-breakpoint-up(lg) {
  .u-border-r-lg-1 { border-right-width: 1px; }
}
