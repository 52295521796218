.breadcrumb {
  font-size: 0.875em;
  background-color: transparent;

  .breadcrumb-item {
    color: $gray-500;
    a {
      color: $gray-500;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    // & + .breadcrumb-item::before {
    //   @include faw-icon($fa-var-angle-right);
    //   color: $primary;
    // }
  }
}
