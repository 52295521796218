
@mixin fontface($name, $file, $weight: normal, $style: normal){
    @font-face {
      font-family: '#{$name}';
      src: url('#{$file}.ttf') format('truetype');
      font-weight: $weight;
      font-style: $style;
    }
  }

@mixin fontfaceWoff($name, $file, $weight: normal, $style: normal){
  @font-face {
    font-family: "#{$name}";
    src: url('#{$file}.woff') format('woff'),url('#{$file}.woff2') format('woff2'),;
    font-weight: $weight;
    font-style: $style;
  }
}