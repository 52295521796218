#filters {
  h2, legend, label {
    font-size: em(14);
    color: $gray-500;
  }

  h2 {
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      width: 65%;
      padding: em(21,14) 0;
      margin-bottom: em(40,14);
      border-bottom: 1px solid $gray-500;
    }
  }
  fieldset {
    margin-bottom: em(24);
    @include media-breakpoint-up(lg) {
      margin-bottom: em(40);
    }
    legend {
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  #collapse-filters-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border-bottom: 1px solid $gray-500;
    padding: em(12,15) 0;
    margin-bottom: em(24,15);
    &[aria-expanded="false"]:after {
      @include faw-icon($fa-var-angle-down);
    }
    &[aria-expanded="true"]:after {
      @include faw-icon($fa-var-angle-up);
    }
    @include media-breakpoint-up(lg) {
      width: 65%;
      border-bottom: 0;
      padding: 0;
      margin: 0;
      &:after {
        content: none !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .collapse {
      display: block;
      height: auto !important;
      visibility: visible;
    }
    .collapsing {
      -webkit-transition: none;
      transition: none;
      display: block;
    }
  }
}